import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Button, ButtonGroup, styled, CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, blue } from "@material-ui/core/colors";
import Stack from '@mui/material/Stack';
import { checkInbetweenSchedule } from '../helpers/functions';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: lime,
    },
});


export default function Scheduler({
    minDate,
    maxDate,
    startHour,
    endHour,
    setStartHour,
    setEndHour,
    openingHours,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarType,
    setEnablePayment,
    scheduledDelivery
}) {
    const [savedDatetime, setSavedDatetime] = React.useState(scheduledDelivery);

    const savePickedDatetime = (value, event) => {

        try {

            var dayOfWeek = value.getDay() - 1;

            setStartHour({
                hour: openingHours[dayOfWeek].startHour.hour,
                minute: openingHours[dayOfWeek].startHour.minute
            })
            console.log(openingHours[dayOfWeek].startHour.minute);

            setEndHour({
                hour: openingHours[dayOfWeek].endHour.minute === 0 ? openingHours[dayOfWeek].endHour.hour - 1 : openingHours[dayOfWeek].endHour.hour,
                minute: openingHours[dayOfWeek].endHour.minute === 0 ? 59 : openingHours[dayOfWeek].endHour.minute
            });

            setSavedDatetime(value);

            let pickedDatetime = JSON.stringify(value);
            localStorage.setItem('@appgenie/deliverySchedule', pickedDatetime);

        } catch (err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
            setEnablePayment(false);
        }
    }

    const checkPickedDate = () => {

        try {

            let pickedDatetime = JSON.stringify(savedDatetime);

            if (pickedDatetime === 'null') {
                setSnackbarMessage('Please choose a valid date and time');
                setSnackbarType('alert');
                setSnackbarOpen(true);
                setEnablePayment(false);
                return false;
            }

            console.log("checkPickedDate triggered");
            // console.log(value);
            // console.log(event);
            var dayOfWeek = savedDatetime.getDay() - 1;
            var isInbetweenSchedule = checkInbetweenSchedule(openingHours, dayOfWeek, savedDatetime);

            if (isInbetweenSchedule === true) {

                setStartHour({
                    hour: openingHours[dayOfWeek].startHour.hour,
                    minute: openingHours[dayOfWeek].startHour.minute
                })

                setEndHour({
                    hour: openingHours[dayOfWeek].endHour.minute === 0 ? openingHours[dayOfWeek].endHour.hour - 1 : openingHours[dayOfWeek].endHour.hour,
                    minute: openingHours[dayOfWeek].endHour.minute === 0 ? 59 : openingHours[dayOfWeek].endHour.minute
                });

                let now = new Date();
                if (now > savedDatetime) {
                    setSnackbarMessage('Please choose a date/time in the future');
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    setEnablePayment(false);
                } else {
                    // setDatetime(value);
                    setSnackbarMessage(``);
                    setSnackbarType('success');
                    setSnackbarOpen(true);
                    setEnablePayment(true);
                    localStorage.setItem('@appgenie/deliverySchedule', pickedDatetime);
                }

            } else {
                var openingHoursStartMinute = openingHours[dayOfWeek].startHour.minute == 0 ? '00' : openingHours[dayOfWeek].startHour.minute;
                var openingHoursEndMinute = openingHours[dayOfWeek].endHour.minute == 0 ? '00' : openingHours[dayOfWeek].endHour.minute;
                var startTime = `${openingHours[dayOfWeek].startHour.hour}:${openingHoursStartMinute}`;
                var endTime = `${openingHours[dayOfWeek].endHour.hour}:${openingHoursEndMinute}`;
                setSnackbarMessage(`Invalid scheduled time. Please choose a time between ${startTime} and ${endTime}`);
                setSnackbarType('error');
                setSnackbarOpen(true);
                setEnablePayment(false);
            }

        } catch (err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
            setEnablePayment(false);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <DateTimePicker
                        // renderInput={(params) => <TextField {...params} size="small" />}
                        renderInput={(props) => <TextField {...props} size="small" />}
                        label="Schedule"
                        value={savedDatetime}
                        onChange={(value, event) => {
                            savePickedDatetime(value, event);
                        }}
                        // minutesStep={30}
                        minDate={minDate}
                        maxDate={maxDate}
                        minTime={new Date(0, 0, 0, startHour.hour, startHour.minute)}
                        maxTime={new Date(0, 0, 0, endHour.hour, endHour.minute)}
                    // format="DD-MM-YYYY"
                    />

                </ThemeProvider>
            </Stack>
        </LocalizationProvider>
    );
}
