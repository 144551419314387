import Api from './Api';

export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export async function kitchenIsClosed(establishment) {
    
    if (typeof establishment.establishment_id !== 'undefined') {
        let res = await Api.kitchenIsClosed(establishment.establishment_id);
        if (res.code === 201) {
            return res.data;
        } else {
            return 1;
        }
    } else {
        return 1;
    }
}

export async function kitchenIsItOpened(payload) {
    let res = await Api.kitchenIsItOpened(payload);
    if (res.code === 201) {
        return res.data;
    } else {
        return 1;
    }
}

export function shoppingCartTotalItems() {
    try {
        let a = JSON.parse(localStorage.getItem('@appgenie/items')) || false;
        let establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || false;
        let appId = 0;
        if (establishment) {
            appId = establishment.establishment_fk_app_id;
        }
        var total = 0;
        if (a) {
            if (parseInt(a.app_id) === parseInt(appId)) {
                console.log('Keeping cart...');
                if (typeof a.items !== 'undefined' && a.items.length > 0) {
                    a.items.forEach(function(item){
                        total = total + item.quantity;
                    });
                } 
            } else {
                console.log('Cleaning cart...');
                console.log(`${a.app_id} !== ${appId}`);
                localStorage.removeItem('@appgenie/items');
            }
        } 
        
        return total;

    } catch(err) {
        console.log(`shoppingCartTotalItems`);
        console.log(err);
        return 0;
    }
}

export function checkInbetweenSchedule(openingHours, dayOfWeek, value) {

    var openingHoursStartMinute = openingHours[dayOfWeek].startHour.minute == 0 ? '00' : openingHours[dayOfWeek].startHour.minute;
    var openingHoursEndMinute = openingHours[dayOfWeek].endHour.minute == 0 ? '00' : openingHours[dayOfWeek].endHour.minute;
    var openingHoursStart = parseInt(`${openingHours[dayOfWeek].startHour.hour}${openingHoursStartMinute}`);
    var openingHoursEnd = parseInt(`${openingHours[dayOfWeek].endHour.hour}${openingHoursEndMinute}`);
    console.log(openingHoursStart);
    console.log(openingHoursEnd);

    var scheduledTimeHour = value.getHours();
    var scheduledTimeMinute = value.getMinutes();
    if (scheduledTimeMinute === 0) {
        scheduledTimeMinute = `${scheduledTimeMinute}0`;
    }
    var scheduledTime = parseInt(`${scheduledTimeHour}${scheduledTimeMinute}`);



    if (scheduledTime >= openingHoursStart && scheduledTime <= openingHoursEnd) {
        return true;
    } else {
        return false;
    }

}
